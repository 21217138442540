import React, { Component } from "react";
import {
  isAndroid,
  isWinPhone,
  isIOS,
  osVersion,
  isBrowser,
  getUA,
} from "react-device-detect";
import ReactGA from "react-ga";
import app_logo from "../img/app_logo_new.png";
import "../App.css";
export default class DeviceDetect extends Component {
  constructor() {
    super();
    this.state = {
      osType: "",
    };
  }
  componentDidMount() {
    setTimeout(
      function () {
        this.osDetection();
      }.bind(this),
      3000
    );
  }
  osDetection() {
    if (getUA.toUpperCase().includes("HUAWEI")) {
      this.setState({ osType: "Huawei" });
      this.openAppGallery();
      return;
    }
    if (isAndroid) {
      this.setState({ osType: "Android" });
      this.openPlayStore();
    } else if (isIOS) {
      this.setState({ osType: "IOS" });
      this.openAppStore();
    } else if (isWinPhone) {
      this.setState({ osType: "Win Phone" });
    } else if (isBrowser) {
      this.openWebPage();
    }
  }

  openPlayStore() {
    ReactGA.event({
      category: "OS Type",
      action: "Android",
      label: `Android Version ${osVersion}`,
    });
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.flashhealth";
  }

  openWebPage() {
    ReactGA.event({
      category: "WEB",
      action: "WEB",
      label: `WEB`,
    });
    window.location.href = "https://flash.health/";
  }

  openAppStore() {
    ReactGA.event({
      category: "OS Type",
      action: "IOS",
      label: `IOS Version  ${osVersion}`,
    });
    window.location.href =
      "https://apps.apple.com/us/app/flash-health/id1552393518";
  }

  openAppGallery() {
    ReactGA.event({
      category: "OS Type",
      action: "Huawei",
      label: `Android Version ${osVersion}`,
    });
    window.location.href = "https://appgallery.huawei.com/app/C107812543";
  }

  render() {
    return (
      <div>
        <header className="App-header">
          <section className="one-fourth" id="logo">
            <img src={app_logo} className="App-logo" alt="logo" />
          </section>
          {!isBrowser ? (
            <p className="Text" style={{ fontWeight: "normal" }}>
              Please wait while we re-route you
              <span class="ellipsis-anim">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </p>
          ) : (
            <div className="StoreButtonContainer">
              <button
                type="button"
                className="ButtonStore"
                onClick={() => {
                  window.location.href =
                    "https://apps.apple.com/us/app/flash-health/id1552393518";
                }}
              >
                <img
                  src="assets/iOSButton.png"
                  className="ButtonImage"
                  alt="logo"
                />
              </button>
              <button
                type="button"
                className="ButtonStore"
                onClick={() => {
                  window.location.href =
                    "https://play.google.com/store/apps/details?id=com.flashhealth";
                }}
              >
                <img
                  src="assets/AndroidButton.png"
                  className="ButtonImage"
                  alt="logo"
                />
              </button>
              <button
                type="button"
                className="ButtonStore"
                onClick={() => {
                  window.location.href =
                    "https://appgallery.huawei.com/app/C107812543";
                }}
              >
                <img
                  src="assets/HuaweiButton.png"
                  className="ButtonImageHuawei"
                  alt="logo"
                />
              </button>
            </div>
          )}
        </header>
      </div>
    );
  }
}
