import './App.css';
import DeviceDetect from './component/DeviceDetect';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-157954014-2');
function App() {
	return (
		<div className='App'>
			<header>
				<DeviceDetect />
			</header>
		</div>
	);
}

export default App;
